export default [
  {
    language: 'ru',
    name: 'Russian',
    nativeName: 'Русский',
  },
  {
    language: 'en',
    name: 'English',
    nativeName: 'English',
  },
]
