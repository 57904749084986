import React from 'react'

type TProps = {
  width?: number | string,
  height?: number | string,
  color?: string,
}

export const PhoneCallIcon: React.FC<TProps> = ({ width = '100%', height = '100%', color }) => {
  return (
    <svg width={width} height={height}  fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d="M16.792 13.391c-.41-.426-.904-.654-1.427-.654-.52 0-1.017.224-1.444.65l-1.333 1.33c-.11-.06-.22-.114-.325-.17a5.321 5.321 0 0 1-.418-.223c-1.25-.794-2.385-1.828-3.474-3.166-.528-.667-.883-1.228-1.14-1.798.346-.317.667-.646.98-.962l.354-.36c.886-.886.886-2.034 0-2.92L7.412 3.965c-.13-.13-.266-.266-.392-.4-.253-.262-.52-.533-.794-.786-.41-.405-.899-.62-1.414-.62-.515 0-1.013.215-1.435.62l-.008.009-1.436 1.447a3.088 3.088 0 0 0-.916 1.963c-.1 1.233.262 2.381.54 3.132.684 1.845 1.706 3.555 3.23 5.387a19.864 19.864 0 0 0 6.614 5.179c.971.46 2.267 1.005 3.715 1.097.089.005.181.009.266.009.975 0 1.794-.35 2.435-1.047.005-.008.013-.013.017-.021.22-.266.473-.507.74-.764.18-.173.366-.355.548-.545.418-.434.637-.941.637-1.46 0-.524-.224-1.026-.65-1.448l-2.317-2.326Zm1.51 4.445c-.003 0-.003.004 0 0-.164.177-.332.338-.514.515-.274.262-.553.536-.815.844-.426.456-.928.671-1.587.671-.063 0-.13 0-.194-.004-1.254-.08-2.419-.57-3.293-.988a18.75 18.75 0 0 1-6.23-4.88c-1.44-1.734-2.402-3.338-3.04-5.06-.392-1.051-.535-1.87-.472-2.643.042-.494.232-.903.583-1.253l1.439-1.44c.207-.194.426-.3.642-.3.266 0 .48.16.616.296l.013.013c.257.24.502.49.76.755.13.135.265.27.4.41l1.153 1.152c.447.447.447.861 0 1.308-.123.123-.24.245-.363.364-.355.363-.693.7-1.06 1.03-.008.008-.017.012-.02.02-.364.364-.296.718-.22.959l.012.038c.3.726.722 1.41 1.364 2.224l.004.004c1.165 1.436 2.393 2.554 3.748 3.411.173.11.35.199.52.283.152.076.295.148.417.224.017.008.034.02.051.03a.914.914 0 0 0 .418.105c.35 0 .57-.22.642-.291l1.443-1.444c.144-.144.372-.317.638-.317.261 0 .477.165.607.309l.009.008 2.326 2.326c.434.43.434.874.004 1.321Z"
        fill={color}
        stroke={color}
        strokeWidth={0.25}
      />
      <path
        d="M16.888 10.744h.003a.67.67 0 0 0 .552-.774 6.61 6.61 0 0 0-1.856-3.58 6.61 6.61 0 0 0-3.579-1.855.673.673 0 0 0-.773.545.664.664 0 0 0 .542.775 5.253 5.253 0 0 1 2.858 1.483 5.28 5.28 0 0 1 1.483 2.859.666.666 0 0 0 .76.548l.01-.001ZM11.396 1.455v.002a.673.673 0 0 0 .55.773 9.491 9.491 0 0 1 5.134 2.661 9.463 9.463 0 0 1 2.66 5.134.666.666 0 0 0 .76.548l.01-.001h.002a.66.66 0 0 0 .549-.766 10.81 10.81 0 0 0-3.038-5.858A10.81 10.81 0 0 0 12.165.91a.668.668 0 0 0-.77.545Z"
        fill={color}
        stroke={color}
        strokeWidth={0.2}
      />
    </svg>
  )
}
