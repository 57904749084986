import { of as observableOf, EMPTY, concat } from 'rxjs'
import { catchError, debounceTime, mergeMap } from 'rxjs/operators'
import Axios from 'axios-observable'
import { isBoolean } from 'lodash'

import * as TApi from '../../types/TApi'
import { SUCCESS_RESP } from '../../types/TApi'

import * as Actions from '../actions'
import { EpicFunc, guardExhaustMap, guardMergeMap, guardSwitchMap, ofType } from './epicHelpers'
import {
  URL_ADDRESS,
  URL_AUTH_BY_PHONE,
  URL_CONFIRM_AUTH,
  URL_PROFILE,
  URL_RESEND_AUTH_CODE,
  URL_REMOVE_CARD,
  URL_LOGOUT,
  URL_GET_SUGGESTIONS,
  URL_GET_GEOCODE,
} from '../../modules/network/urls'
import { authRequestConfig } from '../../utils/requestUtils'
import {
  checkValidAddresses,
  convertAddressFromApi,
  convertProfileFromApi,
  gMapGeocodeToAddressSuggestion,
  gMapToAddressSuggestion,
} from '../../utils/userUtils'
import { convertCardFromApi, sortCardsByTime } from '../../utils/cardUtils'

const authByPhoneEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.AuthByPhone>(a$, Actions.AUTH_BY_PHONE), (b) =>
    b.pipe(
      mergeMap((a) =>
        Axios.post(URL_AUTH_BY_PHONE, a.data).pipe(
          mergeMap((resp: { data: TApi.ApiAuthByPhoneResp }) => {
            if (resp.data && resp.data.Token) {
              return concat(
                observableOf<Actions.Action>(Actions.action(Actions.LOGIN_SUCCESS, resp.data.Token)),
                observableOf<Actions.Action>(Actions.actionEmpty(Actions.AUTH_BY_PHONE_SUCCESS)),
                observableOf<Actions.Action>(Actions.action(Actions.PROFILE, { phone: a.data.phone })),
                observableOf<Actions.Action>(Actions.actionEmpty(Actions.API_PROFILE)),
                observableOf<Actions.Action>(Actions.actionEmpty(Actions.MODAL_CLEAR)),
              )
            }

            if (resp.data) {
              return observableOf<Actions.Action>(Actions.actionEmpty(Actions.AUTH_BY_PHONE_SUCCESS))
            }

            return observableOf<Actions.Action>(Actions.actionEmpty(Actions.AUTH_BY_PHONE_ERROR))
          }),
          catchError((_err) => {
            return observableOf<Actions.Action>(Actions.actionEmpty(Actions.AUTH_BY_PHONE_ERROR))
          }),
        ),
      ),
    ),
  )

const confirmAuthEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ConfirmAuth>(a$, Actions.CONFIRM_AUTH), (b) =>
    b.pipe(
      mergeMap((a) =>
        Axios.post(URL_CONFIRM_AUTH, a.data).pipe(
          mergeMap((resp: { data: TApi.ApiConfirmAuthResp }) => {
            if (resp.data && resp.data.Token) {
              return concat(
                observableOf<Actions.Action>(Actions.action(Actions.LOGIN_SUCCESS, resp.data.Token)),
                observableOf<Actions.Action>(Actions.action(Actions.PROFILE, { phone: a.data.phone })),
                observableOf<Actions.Action>(Actions.actionEmpty(Actions.API_PROFILE)),
                observableOf<Actions.Action>(Actions.actionEmpty(Actions.MODAL_CLEAR)),
              )
            }

            return observableOf<Actions.Action>(Actions.actionEmpty(Actions.CONFIRM_AUTH_ERROR))
          }),
          catchError((_err) => {
            return observableOf<Actions.Action>(Actions.actionEmpty(Actions.CONFIRM_AUTH_ERROR))
          }),
        ),
      ),
    ),
  )

const resendAuthCodeEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ResendAuthCode>(a$, Actions.RESEND_AUTH_CODE), (b) =>
    b.pipe(
      mergeMap((a) =>
        Axios.post(URL_RESEND_AUTH_CODE, a.data).pipe(
          mergeMap(() => {
            return EMPTY
          }),
          catchError((_err) => {
            return EMPTY
          }),
        ),
      ),
    ),
  )

const profileEpic: EpicFunc = (a$, store) =>
  guardExhaustMap(ofType<Actions.ApiProfile>(a$, Actions.API_PROFILE), (b) =>
    b.pipe(
      mergeMap(() =>
        Axios.get(URL_PROFILE, authRequestConfig()).pipe(
          mergeMap((resp: { data: TApi.ApiProfileResp }) => {
            if (!resp.data) {
              return EMPTY
            }

            const data = resp.data
            const currentCards = (store.value.customer.cards || []).length

            const { addresses, cards = [] } = data
            const clientCards = cards.map(convertCardFromApi).sort(sortCardsByTime)
            const isNewCard = currentCards && currentCards < clientCards.length

            const actions: Actions.Action[] = [
              Actions.action(Actions.PROFILE, convertProfileFromApi(data)),
              Actions.action(Actions.ADDRESSES, addresses.map(convertAddressFromApi)),
              Actions.action(Actions.CARDS, {
                cards: clientCards,
                saveCard: !currentCards && !!clientCards.length,
                ...(isNewCard && { newCardId: clientCards[0]?.cardId }),
              }),
            ]

            return observableOf<Actions.Action>(...actions)
          }),
          catchError((_err) => {
            return EMPTY
          }),
        ),
      ),
    ),
  )

const updateProfileEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiUpdateProfile>(a$, Actions.API_UPDATE_PROFILE), (b) =>
    b.pipe(
      mergeMap((a) =>
        Axios.put(URL_PROFILE, a.data, authRequestConfig()).pipe(
          mergeMap((resp: { data: TApi.IUpdateProfileResp }) => {
            if (resp.data && resp.data.detail === SUCCESS_RESP) {
              return observableOf<Actions.Action>(Actions.action(Actions.PROFILE, {
                ...(a.data.first_name && { firstName: a.data.first_name }),
                ...(a.data.last_name && { lastName: a.data.last_name }),
                ...(a.data.email && { email: a.data.email }),
                ...(a.data.avatar_url && { avatarUrl: a.data.avatar_url }),
                ...(isBoolean(a.data.call_me) && { callMe: a.data.call_me }),
                ...(isBoolean(a.data.call_about_change_product) && { callAboutChangeProduct: a.data.call_about_change_product }),
                ...(isBoolean(a.data.call_about_delete_product) && { callAboutDeleteProduct: a.data.call_about_delete_product }),
                ...(isBoolean(a.data.call_about_change_product_weight) && { callAboutChangeProductWeight: a.data.call_about_change_product_weight }),
                ...(isBoolean(a.data.call_change_product) && { callChangeProduct: a.data.call_change_product }),
                ...(isBoolean(a.data.call_delete_product_by_weight) && { callDeleteProductByWeight: a.data.call_delete_product_by_weight }),
              }))
            }

            return observableOf<Actions.Action>(Actions.actionEmpty(Actions.UPDATE_PROFILE_ERROR))
          }),
          catchError((_err) => {
            return observableOf<Actions.Action>(Actions.actionEmpty(Actions.UPDATE_PROFILE_ERROR))
          }),
        ),
      ),
    ),
  )

const logoutEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.Logout>(a$, Actions.LOGOUT), (b) =>
    b.pipe(
      mergeMap(() =>
        Axios.get(URL_LOGOUT).pipe(
          mergeMap(() => {
            const actions: Actions.Action[] = [
              Actions.actionEmpty(Actions.LOGOUT_SUCCESS),
              Actions.actionEmpty(Actions.RESET_CART),
              Actions.actionEmpty(Actions.RESET_ORDERS),
            ]

            return observableOf<Actions.Action>(...actions)
          }),
          catchError((_err) => {
            const actions: Actions.Action[] = [
              Actions.actionEmpty(Actions.LOGOUT_SUCCESS),
              Actions.actionEmpty(Actions.RESET_CART),
              Actions.actionEmpty(Actions.RESET_ORDERS),
            ]

            return observableOf<Actions.Action>(...actions)
          }),
        ),
      ),
    ),
  )

const addAddressEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiAddAddress>(a$, Actions.API_ADD_ADDRESS), (b) =>
    b.pipe(
      mergeMap((a) =>
        Axios.post(URL_ADDRESS, a.data, authRequestConfig()).pipe(
          mergeMap((resp: { data: TApi.IAddAddressResp }) => {
            const { city, street, building, apartment, door_phone, floor, entrance, lat, lon } = a.data

            if (resp.data && resp.data.address_id) {
              return observableOf<Actions.Action>(Actions.action(Actions.UPDATE_ADDRESS, {
                id: resp.data.address_id,
                city,
                street,
                building,
                lat,
                lon,
                ...(apartment && { apartment }),
                ...(door_phone && { intercom: door_phone }),
                ...(floor && { floor }),
                ...(entrance && { entrance }),
              }))
            }

            return observableOf<Actions.Action>(Actions.action(Actions.REMOVE_ADDRESS, {
              city,
              street,
              building,
              lat,
              lon,
              ...(apartment && { apartment }),
              ...(door_phone && { intercom: door_phone }),
              ...(floor && { floor }),
              ...(entrance && { entrance }),
            }))
          }),
          catchError((_err) => {
            return EMPTY
          }),
        ),
      ),
    ),
  )

const removeAddressEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiRemoveAddress>(a$, Actions.API_REMOVE_ADDRESS), (b) =>
    b.pipe(
      mergeMap((a) =>
        Axios.delete(URL_ADDRESS, {
          ...authRequestConfig(),
          data: {
            ...a.data,
          }
        }).pipe(
          mergeMap(() => {
            return EMPTY
          }),
          catchError((_err) => {
            return EMPTY
          }),
        ),
      ),
    ),
  )

const setDefaultAddressEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.SetDefaultAddress>(a$, Actions.SET_DEFAULT_ADDRESS), (b) =>
    b.pipe(
      mergeMap((a) => {
        const { lat, lon } = a.data

        if (lat && lon) {
          return observableOf<Actions.Action>(Actions.action(Actions.DEFAULT_ADDRESS, a.data))
        }

        return EMPTY
      }),
    ),
  )

const addressSuggestionsEpic: EpicFunc = (a$, _store) =>
  guardMergeMap(
    ofType<Actions.ApiAddressSuggestions>(a$, Actions.API_ADDRESS_SUGGESTIONS).pipe(debounceTime(1000)),
    (b) =>
    b.pipe(
      mergeMap((a) =>
        Axios.get(URL_GET_SUGGESTIONS, {
          params: {
            query: a.data.query,
          },
        }).pipe(
          mergeMap((resp: { data: TApi.GMapSuggestResp }) => {
            const result = (resp.data || []).map(gMapToAddressSuggestion)
            const suggestions = checkValidAddresses(result)

            return observableOf<Actions.Action>(Actions.action(Actions.ADDRESS_SUGGESTIONS, {
              suggestions,
              query: a.data.query,
            }))
          }),
          catchError((_err) => {
            return EMPTY
          }),
        ),
      ),
    ),
  )

const addressGeocodingEpic: EpicFunc = (a$, _store) =>
  guardSwitchMap(ofType<Actions.ApiAddressGeocoding>(a$, Actions.API_ADDRESS_GEOCODING), (b) =>
    b.pipe(
      mergeMap((a) =>
        Axios.get(URL_GET_GEOCODE, {
          params: {
            query: a.data.query,
          },
        }).pipe(
          mergeMap((resp: { data: TApi.GMapGeocodeResp }) => {
            if (resp.data && Array.isArray(resp.data) && resp.data.length > 0) {
              return observableOf<Actions.Action>(
                Actions.action(Actions.ADDRESS_GEOCODING, gMapGeocodeToAddressSuggestion(resp.data[0])))
            }

            return observableOf<Actions.Action>(Actions.action(Actions.ADDRESS_GEOCODING, null))
          }),
          catchError((_err) => {
            return observableOf<Actions.Action>(Actions.action(Actions.ADDRESS_GEOCODING, null))
          }),
        ),
      ),
    ),
  )

const removeCardEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiRemoveCard>(a$, Actions.API_REMOVE_CARD), (b) =>
    b.pipe(
      mergeMap((a) =>
        Axios.delete(URL_REMOVE_CARD(a.data.id), {
          ...authRequestConfig(),
          data: {
            ...a.data,
          }
        }).pipe(
          mergeMap(() => {
            return observableOf<Actions.Action>(Actions.actionEmpty(Actions.API_PROFILE))
          }),
          catchError((_err) => {
            return EMPTY
          }),
        ),
      ),
    ),
  )

export const customerEpics: EpicFunc[] = [
  authByPhoneEpic,
  confirmAuthEpic,
  resendAuthCodeEpic,
  profileEpic,
  updateProfileEpic,
  addAddressEpic,
  removeAddressEpic,
  setDefaultAddressEpic,
  addressSuggestionsEpic,
  addressGeocodingEpic,
  removeCardEpic,
  logoutEpic,
]
