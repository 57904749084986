import { MessageKeys } from '../messages'
import countries from './countries'

export const ru: { [x in MessageKeys]: string } = {
  ...countries,
  DownloadApp: 'Скачать приложение',
  DeliveryFresh: 'Доставляем свежие продукты\nс рынка',
  DeliveryFor: 'За 90 минут и от 49 рублей с гарантией свежести',
  Country: 'Страна',
  AboutProduct: 'О товаре',
  AddToCart: 'Положить в корзину',
  OutOfStock: 'Нет в наличии',
  Total: 'Итого',
  Seller: 'Продавец',
  Address: 'Адрес',
  INN: 'ИНН',
  Pcs: 'шт',
  Kg: 'кг',
  Gram: 'г',
  Litre: 'л',
  Millilitre: 'мл',
  Back: 'Назад',
  All: 'Все',
  ResultRequest: 'Результаты по запросу',
  Other: 'Другое',
  Documents: 'Документы',
  Treaty: 'Договор',
  Agreement: 'Соглашение',
  Requisites: 'Реквизиты',
  UserAgreement: 'Пользовательское соглашение',
  PrivacyPolicy: 'Политика конфиденциальности',
  Name: 'Название',
  Understand: 'Понятно',
  RequiredAuth: 'Для просмотра нужно авторизоваться',
  LoginByPhone: 'Войдите в профиль с помощью номера телефона',
  Login: 'Войти',
  Cart: 'Корзина',
  CartEmpty: 'В корзине пусто',
  PlaceItemsCart: 'Положите в неё товары, чтобы оформить заказ',
  ToCatalog: 'В каталог',
  Comment: 'Комментарий',
  ProductWishes: 'Пожелания по товарам',
  TotalPayable: 'Итого к оплате',
  CartProductByWeightInfo: 'В корзине есть весовой товар, значит возможно изменение суммы заказа в обе стороны',
  CheckoutFor: 'Оформить заказ на',
  WeightProductPrice: 'Весовой товар и цена',
  CartProductByWeightFullInfo: 'Так как в корзине есть весовой товар, то возможно изменения суммы заказа' +
    ' в обе стороны. Точная сумма будет сформирована после сборки товара.\n' +
    ' Итоговая сумма резервируется на вашем счете, а точная сумма списывается после сбора заказа.',
  EmptyTrash: 'Очистить корзину',
  Cancel: 'Отмена',
  SavedCards: 'Сохранённые карты',
  CardDataSecure: 'Данные карт надёжно защищены',
  YourBankCards: 'Здесь будут ваши банковские карты',
  SaveCardByOrder: 'Сохранить карту можно при оплате заказа',
  DeleteCard: 'Удалить карту',
  Delete: 'Удалить',
  PlacingOrder: 'Оформление заказа',
  Recipient: 'Получатель',
  NameOrCompany: 'Имя или название компании',
  ReceiptMethod: 'Способ получения',
  DateAndTime: 'Дата и время',
  PaymentMethod: 'Способ оплаты',
  HowCardPaymentWorks: 'Как работает оплата по карте',
  SpecifyAddress: 'Указать адрес',
  SpecifyAnotherAddress: 'Указать другой адрес',
  PlaceOrder: 'Оформить заказ',
  UpMinimumOrder: 'До минимального заказа',
  HowPaymentWorks: 'Как работает оплата?',
  MarketClosingSoon: 'Рынок скоро закрывается!',
  MarketClosingSoonInfo: 'Извините, рынок скоро закроется, вы можете сделать заказ завтра, ' +
    'продавцы как раз закупят всё свеженькое!',
  ItsPity: 'Очень жаль',
  Order: 'Заказ',
  From: 'От',
  Canceled: 'Отменён',
  MoneyWillReturned: 'Деньги будут возвращены на карту',
  PayOrderOnTime: 'Оплатите заказ, чтобы мы начали его собирать и доставили вовремя',
  Pay: 'Оплатить',
  PayExtra: 'Доплатить',
  Delivery: 'Доставка',
  Pickup: 'Самовывоз',
  Free: 'Бесплатно',
  RepeatOrder: 'Повторить заказ',
  ConnectWithUs: 'Связаться с нами',
  CancelOrder: 'Отменить заказ',
  OrderPrice: 'Сумма заказа',
  OrderCancellation: 'Отмена заказа',
  ApproveOrderCancel: 'Уверены, что хотите отменить заказ? Восстановить заказ будет нельзя. Ещё не поздно передумать.',
  NotCancel: 'Не отменять',
  MyOrders: 'Мои заказы',
  Profile: 'Профиль',
  Purchases: 'Покупки',
  Communications: 'Коммуникации',
  Logout: 'Выйти',
  WhatName: 'What is your name?',
  Support: 'Служба поддержки',
  Exit: 'Выход',
  ConfirmLogout: 'Уверены, что хотите выйти?',
  AnswerYourQuestions: 'С радостью ответим на ваши вопросы',
  OnlineChat: 'Онлайн-чат',
  AddingAddress: 'Добавление адреса',
  Add: 'Добавить',
  CityAndStreet: 'Город и улица',
  Street: 'Улица',
  StreetShort: 'Ул',
  House: 'Дом',
  HouseShort: 'Д',
  Flat: 'Квартира',
  FlatShort: 'Кв.',
  Entrance: 'Подъезд',
  EntranceShort: 'Под.',
  Floor: 'Этаж',
  Intercom: 'Домофон',
  IntercomShort: 'Домоф.',
  Code: 'Код',
  CityRequired: 'Город обязателен для оформления заказа',
  SpecifyHouse: 'Укажите дом',
  HowLastOrder: 'Как вам последний заказ?',
  WhatLike: 'Что понравилось?',
  PostReview: 'Отправить отзыв',
  Select: 'Выбрать',
  Cash: 'Наличными',
  NewCard: 'Новой картой',
  ReplacingGoods: 'При замене товаров могут быть дополнительные списания',
  ReplacingGoodsInfo: 'Если вы решите заменить один товар на другой, мы пересчитаем сумму заказа. ' +
    'Если сумма вырастет, спишем недостающую сумму с вашей карты',
  FreezeMoney: 'Сперва заморозим, затем спишем',
  FreezeMoneyInfo: 'В момент заказа мы замораживаем сумму покупки на вашей карте, а списываем уже после сбора заказа',
  OrderPaid: 'Заказ оплачен',
  PaymentFailed: 'Оплата не прошла',
  CheckCardValid: 'Проверьте баланс карты или её реквизиты. Возможно, в них ошибка',
  AddAddress: 'Добавить адрес',
  RemoveAddress: 'Удалить адрес?',
  PickupAddress: 'Адрес самовывоза',
  MarketHours: 'Часы работы рынка',
  DailyFrom: 'Ежедневно с',
  Before: 'До',
  After: 'После',
  RateOrder: 'Оценить заказ',
  CardOnline: 'Картой онлайн',
  ProductSearch: 'Поиск по товарам',
  ShowAllProducts: 'Показать все товары',
  NothingFound: 'Ничего не найдено',
  TryAnotherRequest: 'Попробуйте другой запрос или поищите товар в Каталоге',
  YourCity: 'Ваш город',
  ChooseAnother: 'Выбрать другой',
  YesThatRight: 'Да, верно',
  CitySelection: 'Выбор города',
  NotOpenedInOtherCity: 'В других городах пока не открылись, но очень скоро начнём работу!',
  ItemsOrder: 'Товары в заказе',
  Goods: 'Товаров',
  Today: 'Сегодня',
  Tomorrow: 'Завтра',
  AsSoonAsPossible: 'Как можно скорее',
  DuringDay: 'В течение дня',
  DeliveryAsSoonAsPossible: 'Доставка как можно скорее',
  DeliveryAsSoonAsPossibleInfo: 'Срочная доставка в самое ближайшее время.' +
    ' Обычно такие заказы доставляем течение 90 минут от времени заказа',
  DeliveryDuringDay: 'Доставка в течение дня',
  DeliveryDuringDayInfo: 'Доставка без привязки к конкретному времени.' +
    ' Привезём заказ в течение дня по мере загруженности службы доставки',
  CanPickUp: 'Можно забрать',
  With: 'С',
  LoginSignUp: 'Вход / регистрация',
  UserOfferPolicyAgree1: 'Продолжая вы соглашаетесь с',
  UserOfferPolicyAgree2: 'офертой',
  UserOfferPolicyAgree3: 'и',
  UserOfferPolicyAgree4: 'политикой обработки персональных данных',
  NeedPhoneToOrder: 'Нужен телефон, чтобы оформить заказ',
  CodeSent: 'На телефон отправлен код подтверждения',
  CodeFromSMS: 'Код из СМС',
  InvalidCode: 'Неверный код подтверждения',
  Resend: 'Отправить повторно',
  SubmitCode: 'Отправить код',
  GoodsOutOfStock: 'Товары закончились',
  ContinueWithout: 'Продолжить без них',
  Replace: 'Заменить',
  NeedPayExtra: 'Нужно доплатить',
  WaitingPayment: 'Ожидает оплаты',
  AcceptedWork: 'Принят в работу',
  Collecting: 'Собирается',
  OnCourier: 'В пути',
  Delivered: 'Доставлен',
  NumCharactersNoMore: '{field} - кол-во символов должно быть не больше {max}',
  ItemsDiscount: 'Скидка на товары',
  CookiesPolicy1: 'Мы используем cookies на нашем сайте. Продолжая работу с сайтом, ' +
    'Вы соглашаетесь с использование файлов cookie и ',
  CookiesPolicy2: 'политикой конфиденциальности.',
  Error: 'Ошибка',
  NotDeliverHere: 'Сюда пока не доставляем',
  SorryNotDeliverAddress: 'Извините, мы пока не доставляем по вашему адресу. Мы работаем над расширением ' +
    'зоны доставки и, возможно, совсем скоро доберёмся до вас!',
  OrderCancelled: 'Заказ отменён',
  ReturningMoneyInfo: 'Срок возврата средств на карту может составлять до 7 банковских дней. ' +
    'Обычно средства возвращаются быстрее. В случае, если средства не вернулись в течение 7 банковских дней, ' +
    'пожалуйста, обратитесь в банк, выпустивший вашу карту. Редко, но бывает, что банки не уведомляют о возврате.',
  EnteredCityNotMatchCityMarket: 'Введенный город не соответствует городу рынка',
  SorryUnableCalcDeliveryCost: 'Извините, не удалось вычислить стоимость доставки',
  DeliveryCostLessMin: 'Стоимость доставки меньше минимальной',
  Promo: 'Промо',
  AllPromo: 'Все промо продукты',
  AddMoreItems: 'Может пригодиться',
  OneLastCraving: 'Что-нибудь ещё?',
  NoThankYou: 'Нет, спасибо',
  GoToPay: 'К оплате',
  callMe: 'Позвоните мне',
  callMeTitle: 'Замена товара и веса',
  cllMeBlockTitle: 'Замена товара и изменение веса',
  callMeDesc: 'Если товар закончился, оказался некачественным или изменился вес',
  notCallMe: 'Не звоните мне',
  aboutChangeProduct: 'На счёт замены товара',
  aboutDeleteProduct: 'На счёт удаления товара',
  aboutChangeProductWeight: 'Если изменится вес товара',
  changeProduct: 'Подберите замену',
  deleteProductByWeight: 'Удалите товар, если его вес меняется больше, чем на 10%',
  deleteProduct: 'Удалите товар',
  changeWeight: 'Измените вес товара',
  ifNotAnswer: 'Если не отвечу',
}
