export enum COLORS {
  BLACK = '#000000',
  WHITE = '#ffffff',

  BLACK_THEME = '#1d1d1b',
  PURPLE_THEME = '#6516ce',
  PURPLE_DARK_THEME = '#4c0f9e',

  GRAY_ELEM = '#bec2cd',
  GRAY_DARK_ELEM = '#a0a3b5',
  GRAY_LIGHT_ELEM = '#e8e8e8',
  GRAY_LIGHT_ELEM_2 = '#e7e8ea',

  GRAY_TEXT = '#858897',
  GRAY_RADIO = '#BEC2CD',

  RED_THEME = '#eb5757',
  ORANGE_THEME = '#f59e4d',
  YELLOW_THEME = '#f8cd46',

  TRANSPARENT = 'transparent',
}

export enum ORDER_COLORS {
  NEW = '#5bc258',
  IN_ASSEMBLY = '#f59e4d',
  COURIER = '#f59e4d',
  CANCELED = '#eb5757',
  DELIVERED = '#6516ce',
  NOT_PAID = '#eb5757',
}
