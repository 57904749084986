import { Reducer } from 'redux'

import { IAddress, IAddressSuggestion, ICard, ISignUpData, IUser } from '../../types/TClient'

import * as Actions from '../actions'
import { compareAddresses } from '../../utils/userUtils'
import { LocalStorage } from '../../modules/localStorage'

export type MutableStateUser = {
  profile: IUser | null,
  addresses: {
    default?: IAddress,
    all?: IAddress[],
  },
  allowAddresses?: IAddress[],
  signUpData: ISignUpData | null,
  token: string | null,
  tokenFCM: string | null,
  updateProfileLoading: boolean,
  loginLoading: boolean,
  signUpLoading: boolean,
  confirmAuthLoading: boolean,
  logoutPending: boolean,
  changePasswordLoading: boolean,
  resetPasswordLoading: boolean,
  addressSuggestions: IAddressSuggestion[],
  addressGeocoding: IAddressSuggestion | null,
  geocodingLoading: boolean,
  fromGeo: boolean,
  confirmAuthError: boolean,
  cityLoading: boolean,
  cards: ICard[],
  testMode: boolean,
}

export type StateCustomer = Readonly<MutableStateUser>

const defStateUsers: StateCustomer = {
  profile: null,
  addresses: {},
  signUpData: null,
  token: null,
  tokenFCM: null,
  updateProfileLoading: false,
  loginLoading: false,
  signUpLoading: false,
  confirmAuthLoading: false,
  changePasswordLoading: false,
  resetPasswordLoading: false,
  logoutPending: false,
  addressSuggestions: [],
  addressGeocoding: null,
  geocodingLoading: false,
  fromGeo: false,
  confirmAuthError: false,
  cityLoading: false,
  cards: [],
  testMode: false,
}

export const customer: Reducer<StateCustomer, Actions.Action> = (s = defStateUsers, a): StateCustomer => {
  switch (a.type) {
    case Actions.TOGGLE_TEST_MODE:
      return {
        ...s,
        testMode: !s.testMode,
      }
    case Actions.LOGIN_SUCCESS:
      if (a.data) {
        LocalStorage.setSid(a.data)
      }

      return {
        ...s,
        token: a.data,
        loginLoading: false,
      }
    case Actions.AUTH_BY_PHONE:
      return {
        ...s,
        loginLoading: true,
        confirmAuthError: false,
      }
    case Actions.AUTH_BY_PHONE_SUCCESS:
      return {
        ...s,
        loginLoading: false,
        confirmAuthError: false,
      }
    case Actions.AUTH_BY_PHONE_ERROR:
      return {
        ...s,
        loginLoading: false,
      }

    case Actions.CONFIRM_AUTH:
      return {
        ...s,
        confirmAuthLoading: true,
        confirmAuthError: false,
      }
    case Actions.CONFIRM_AUTH_SUCCESS:
      if (a.data.token) {
        LocalStorage.setSid(a.data.token)
      }

      return {
        ...s,
        signUpData: null,
        token: a.data.token,
        profile: {
          ...s.profile,
          ...a.data.user,
        },
        confirmAuthLoading: false,
      }
    case Actions.CONFIRM_AUTH_ERROR:
      return {
        ...s,
        confirmAuthError: true,
        confirmAuthLoading: false,
      }

    case Actions.API_PROFILE:
      return s
    case Actions.PROFILE:
      return {
        ...s,
        profile: {
          ...s.profile,
          ...a.data,
        },
        updateProfileLoading: false,
      }
    case Actions.ADDRESSES:
      return {
        ...s,
        addresses: {
          ...s.addresses,
          all: a.data,
        },
      }
    case Actions.ADD_ADDRESS: {
      const addresses = s.addresses.all || []

      return {
        ...s,
        addresses: {
          ...s.addresses,
          all: [...addresses, a.data],
          ...(addresses.length === 0 && { default: a.data }),
        },
      }
    }
    case Actions.UPDATE_ADDRESS: {
      const addresses = s.addresses.all || []
      const { default: def } = s.addresses

      return {
        ...s,
        addresses: {
          default: def ? (compareAddresses(def, a.data, true) ? a.data : def) : undefined,
          all: addresses.map((address) => (compareAddresses(address, a.data, true) ? a.data : address)),
        },
      }
    }
    case Actions.UPDATE_ADDRESS_COORDS: {
      const { id, lat, lon } = a.data
      const { default: def } = s.addresses
      const addresses = s.addresses.all || []

      return {
        ...s,
        addresses: {
          default: def && def.id === id ? { ...def, lat, lon } : def,
          all: addresses.map((address) => (address.id === id ? { ...address, lat, lon } : address)),
        },
      }
    }
    case Actions.REMOVE_ADDRESS: {
      const addresses = s.addresses.all || []
      const { default: def } = s.addresses

      return {
        ...s,
        addresses: {
          default: def ? (compareAddresses(def, a.data, true) ? undefined : def) : undefined,
          all: addresses.filter((address) => !compareAddresses(address, a.data, true)),
        },
      }
    }
    case Actions.DEFAULT_ADDRESS: {
      return {
        ...s,
        addresses: {
          ...s.addresses,
          default: a.data,
        },
      }
    }
    case Actions.ADDRESS_SUGGESTIONS:
      return {
        ...s,
        addressSuggestions: a.data.suggestions.filter((item) => item.unrestricted_value),
        fromGeo: !!a.data.fromGeo,
        cityLoading: false,
      }
    case Actions.ADDRESS_SUGGESTIONS_CLEAR:
      return {
        ...s,
        addressSuggestions: [],
        fromGeo: false,
        cityLoading: false,
      }
    case Actions.API_ADDRESS_GEOCODING:
      return {
        ...s,
        addressGeocoding: null,
        geocodingLoading: true,
      }
    case Actions.ADDRESS_GEOCODING:
      return {
        ...s,
        addressGeocoding: a.data,
        geocodingLoading: false,
      }
    case Actions.CARDS:
      return {
        ...s,
        cards: a.data.cards,
      }
    case Actions.API_UPDATE_PROFILE:
      return {
        ...s,
        updateProfileLoading: true,
      }
    case Actions.UPDATE_PROFILE_ERROR:
      return {
        ...s,
        updateProfileLoading: false,
      }
    case Actions.LOGOUT_SUCCESS:
      LocalStorage.removeSid()

      return {
        ...s,
        ...defStateUsers,
      }
  }
  return s
}
