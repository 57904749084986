import React from 'react'

type TProps = {
  width?: number | string,
  height?: number | string,
  color?: string,
}

export const LogoIcon: React.FC<TProps> = ({ width = '100%', height = '100%', color }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 75 38' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fill={color || '#8B4292'} d='M10.778 7.037H6.816v11.576H3.962V7.037H0V4.45h10.778v2.588ZM22.555 14.34c0 2.66-2.109 4.273-4.76 4.273h-5.494V4.432h5.034c2.712 0 4.715 1.373 4.715 4.103a3.606 3.606 0 0 1-1.072 2.66 3.696 3.696 0 0 1 1.577 3.146Zm-7.383-7.382v3.279h1.897c1.338 0 2.287-.381 2.287-1.684s-.949-1.595-2.287-1.595h-1.897Zm4.689 7.347c0-1.214-.886-1.772-2.242-1.772h-2.447v3.483h2.447c1.356.009 2.242-.496 2.242-1.71ZM23.806 11.531a7.143 7.143 0 1 1 14.284.283 7.143 7.143 0 0 1-14.284-.283Zm11.522 0a4.37 4.37 0 1 0-2.672 4.088 4.36 4.36 0 0 0 2.663-4.088h.01ZM51.725 18.613h-2.872V9.306l-5.522 9.307h-3.323V4.432h2.88v9.341l5.53-9.324h3.298l.009 14.164ZM48.118 0a1.897 1.897 0 0 1-.408.824 2.1 2.1 0 0 1-2.89 0A1.896 1.896 0 0 1 44.414 0l-1.578.576A3.545 3.545 0 0 0 48.81 2.35c.471-.486.78-1.105.886-1.773L48.118 0Z'/>
      <path fill={color || '#1D1D1B'} d='M13.914 27.006a4.67 4.67 0 0 1-4.919 4.75H6.762v4.654H3.863V22.229h5.132a4.672 4.672 0 0 1 4.92 4.777Zm-2.659 0a2.066 2.066 0 0 0-2.251-2.171H6.762v4.334h2.26a2.065 2.065 0 0 0 2.224-2.163h.01ZM25.65 31.863c0 2.872-2.305 4.547-4.875 4.547h-5.15V22.23h2.88v5.079h2.243c2.597.017 4.901 1.701 4.901 4.555Zm-2.66 0a1.986 1.986 0 0 0-2.18-1.95h-2.278v3.909h2.242a1.976 1.976 0 0 0 2.18-1.959h.036Zm7.268-9.616v14.18h-2.89v-14.18h2.89ZM44.324 22.247v14.18h-2.853v-6.026h-5.744v6.027h-2.871V22.247h2.871v5.592h5.744v-5.592h2.853ZM46.267 29.328a7.144 7.144 0 1 1 14.285.284 7.144 7.144 0 0 1-14.285-.284Zm11.522 0a4.378 4.378 0 1 0-1.257 3.12 4.37 4.37 0 0 0 1.248-3.12h.01ZM67.033 30.649H65.35v5.761h-2.88V22.229h2.871v5.726h1.693l4.343-5.726H74.7l-5.318 7.09 5.62 7.091h-3.378l-4.59-5.761Z'/>
      <path fill={color || '#000'} d='M53.852 14.598a5.078 5.078 0 0 1 3.704 6.824 8.105 8.105 0 0 1-1.382-.77 4.015 4.015 0 0 1-1.773-2.917c-.089-.638-.142-1.285-.248-1.923-.062-.381-.195-.753-.293-1.126l-.008-.088ZM57.875 22.521a4.431 4.431 0 0 1 2.41-3.102 4.297 4.297 0 0 1 3.546-.177 6.253 6.253 0 0 0-1.064 1.046c-.283.354-.567.7-.886 1.045a3.544 3.544 0 0 1-2.942 1.25c-.364.027-.7-.009-1.064-.062Z'/>
    </svg>
  )
}
