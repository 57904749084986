export const IOS_APP = 'https://apps.apple.com/ru/app/%D1%82%D0%B2%D0%BE%D0%B9-%D1%80%D1%8B%D0%BD%D0%BE%D0%BA/id1551947692'
export const ANDROID_APP = 'https://play.google.com/store/apps/details?id=com.tvoyrynok'

export const API_URL = process.env.REACT_APP_API_URL || 'https://app-test.mosmarket.online'
export const APP_LINK = process.env.REACT_APP_APP_LINK || 'tvoyrynok://'
export const APP_API_URL = process.env.REACT_APP_APP_API_URL || 'https://api-test.mosmarket.online'

export const PRIVACY_POLICY_URI =
  process.env.REACT_APP_PRIVACY_POLICY_URI || 'https://storage.yandexcloud.net/tvoyrynok/customer/PRIVACY_POLICY.pdf'
export const OFFER_AGREEMENT_URI =
  process.env.REACT_APP_USER_AGREEMENT_URI || 'https://storage.yandexcloud.net/tvoyrynok/customer/OFFER_AGREEMENT.pdf'

export const PHONE_CONTACT = '+7 (800) 333 79 41'
export const EMAIL_CONTACT = 'help@tvoyrynok.online'

export const TELEGRAM_BOT =
  process.env.REACT_APP_TELEGRAM_CONTACT_BOT || 'TvoyRynok_Support_Test_Bot'
export const VIBER_BOT = process.env.REACT_APP_VIBER_CONTACT_BOT || 'tvoyrynoktest'
export const CHAT_LINK = process.env.REACT_APP_CHAT_LINK || 'https://tvoyrynok.online/chat'

export const ANALYTICS = process.env.REACT_APP_ANALYTICS === 'true'
export const YANDEX_METRIKA = 88636354
