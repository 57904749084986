import * as React from 'react'
import { connect } from 'react-redux'
import { Modal } from 'semantic-ui-react'
import loadable from '@loadable/component'

import './index.scss'

import { State } from '../../store/reducer'
import { EModalType, IModal } from '../../store/reducers/modals'
import * as Actions from '../../store/actions'
// import { DeliveryChangeModal } from '../../components/Modal/DeliveryChangeModal'
// import { OrderModal } from '../../components/Modal/OrderModal'
const InfoModal = loadable(() => import('../../components/Modal/ModalInfo'))
const MarketModal = loadable(() => import('../../components/Modal/MarketModal'))
const ConfirmModal = loadable(() => import('../../components/Modal/ConfirmModal'))
const OutOfStockModal = loadable(() => import('../../components/Modal/OutOfStockModal'))
const AuthModal = loadable(() => import('../../components/Modal/AuthModal'))
const PaymentInfoModal = loadable(() => import('../../components/Modal/ModalPaymentInfo'))
const ProductsModal = loadable(() => import('../../components/Modal/ProductsModal'))
const MessengersModal = loadable(() => import('../../components/Modal/ModalMessengers'))
const PayModal = loadable(() => import('../../components/Modal/PayModal'))
const AddressModal = loadable(() => import('../../components/Modal/AddressModal'))
const PaymentModal = loadable(() => import('../../components/Modal/PaymentModal'))
const DeliveryDateModal = loadable(() => import('../../components/Modal/DeliveryDateModal'))
const ReviewOrderModal = loadable(() => import('../../components/Modal/ReviewOrderModal'))
const ProductModal = loadable(() => import('../../components/Modal/ProductModal'))
const CallMeModal = loadable(() => import('../../components/Modal/CallMeModal'))

type Props = {
  modal: IModal | null,
  close(): any,
  closeAll(): any,
}

type IState = {
  modal: IModal | null,
  key: number,
}

class ModalCmp extends React.Component<Props, IState> {
  state = {
    modal: null,
    key: 1,
  }

  render() {
    const cmp1 = this.renderModal(this.state.modal)
    const cmp2 = this.renderModal(this.props.modal)
    const result = []

    if (cmp1) {
      result.push(cmp1)
    }

    if (cmp2) {
      result.push(cmp2)
    }

    return result.length ? result : null
  }

  private renderModal(modal: IModal | null) {
    return modal ? (
      <Modal
        open
        closeIcon={!!modal.close}
        basic={!!modal.basic}
        key={modal.type}
        size={modal.size ? modal.size : 'small'}
        style={modal.style}
        onClose={this.props.close}
      >
        {this.renderContent(modal)}
      </Modal>
    ) : null
  }

  private renderContent(modal: IModal) {
    switch (modal.type) {
      // case EModalType.MODAL_PRODUCT:
      //   return <ProductModal onClose={this.props.close} {...modal.props} />
      // case EModalType.MODAL_DELIVERY_CHANGE:
      //   return <DeliveryChangeModal onClose={this.props.close} {...modal.props} />
      // case EModalType.MODAL_ORDER:
      //   return <OrderModal onClose={this.props.close} {...modal.props} />
      case EModalType.MODAL_INFO:
        return <InfoModal onClose={this.props.close} {...modal.props} />
      case EModalType.MODAL_MARKET:
        return <MarketModal onClose={this.props.close} {...modal.props} />
      case EModalType.MODAL_CONFIRM:
        return <ConfirmModal onClose={this.props.close} {...modal.props} />
      case EModalType.MODAL_OUT_OF_STOCK:
        return <OutOfStockModal onClose={this.props.close} {...modal.props} />
      case EModalType.MODAL_AUTH:
        return <AuthModal onClose={this.props.close} {...modal.props} />
      case EModalType.MODAL_PAYMENT_INFO:
        return <PaymentInfoModal onClose={this.props.close} {...modal.props} />
      case EModalType.MODAL_PRODUCTS:
        return <ProductsModal onClose={this.props.close} {...modal.props} />
      case EModalType.MODAL_MESSENGERS:
        return <MessengersModal onClose={this.props.close} {...modal.props} />
      case EModalType.MODAL_PAY:
        return <PayModal onClose={this.props.close} {...modal.props} />
      case EModalType.MODAL_ADDRESS:
        return <AddressModal onClose={this.props.close} {...modal.props} />
      case EModalType.MODAL_PAYMENT:
        return <PaymentModal onClose={this.props.close} {...modal.props} />
      case EModalType.MODAL_DELIVERY_DATE:
        return <DeliveryDateModal onClose={this.props.close} {...modal.props} />
      case EModalType.MODAL_REVIEW_ORDER:
        return <ReviewOrderModal onClose={this.props.close} {...modal.props} />
      case EModalType.MODAL_PRODUCT:
        return <ProductModal onClose={this.props.close} {...modal.props} />
      case EModalType.MODAL_CUSTOMER_CALL_ME:
        return <CallMeModal onClose={this.props.close} />
    }
  }
}

const mapStateToProps = (s: State) => {
  const modals = s.modals.modals
  if (!modals.length) {
    return { modal: null }
  }
  return { modal: modals[modals.length - 1] }
}

const mapDispatchToProps = {
  close: () => Actions.action(Actions.MODAL_POP, {}),
  closeAll: () => Actions.actionEmpty(Actions.MODAL_CLEAR),
}

export const ModalContainer = connect(mapStateToProps, mapDispatchToProps)(ModalCmp)
