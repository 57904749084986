import React from 'react'
import { Reducer } from 'redux'

import {
  IOrder,
  IProduct,
  ICard,
  EPaymentType,
} from '../../types/TClient'

import * as Actions from '../actions'

export enum EModalType {
  MODAL_DELIVERY_CHANGE = 'ORDERS_DELIVERY_CHANGE',
  MODAL_ORDER = 'MODAL_ORDER',
  MODAL_CUSTOMER_CALL_ME = 'MODAL_CUSTOMER_CALL_ME',
  MODAL_INFO = 'MODAL_INFO',
  MODAL_MARKET = 'MODAL_MARKET',
  MODAL_CONFIRM = 'MODAL_CONFIRM',
  MODAL_OUT_OF_STOCK = 'MODAL_OUT_OF_STOCK',
  MODAL_AUTH = 'MODAL_AUTH',
  MODAL_PAYMENT_INFO = 'MODAL_PAYMENT_INFO',
  MODAL_PRODUCTS = 'MODAL_PRODUCTS',
  MODAL_MESSENGERS = 'MODAL_MESSENGERS',
  MODAL_PAY = 'MODAL_PAY',
  MODAL_ADDRESS = 'MODAL_ADDRESS',
  MODAL_PAYMENT = 'MODAL_PAYMENT',
  MODAL_DELIVERY_DATE = 'MODAL_DELIVERY_DATE',
  MODAL_REVIEW_ORDER = 'MODAL_REVIEW_ORDER',
  MODAL_PRODUCT = 'MODAL_PRODUCT',
}

export interface IModalCommon {
  size?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen',
  basic?: boolean,
  close?: boolean,
  style?: React.CSSProperties,
}

export interface IModalProduct extends IModalCommon {
  type: EModalType.MODAL_PRODUCT,
  props: {
    product?: IProduct,
    productId: string,
  },
}

export interface IModalDeliveryChange extends IModalCommon {
  type: EModalType.MODAL_DELIVERY_CHANGE,
  props: {
    title: string,
    order: IOrder,
  },
}

export interface IModalOrder extends IModalCommon {
  type: EModalType.MODAL_ORDER,
  props: {
    order: IOrder,
    marketId: string,
  },
}

export interface IModalCustomerCallMe extends IModalCommon {
  type: EModalType.MODAL_CUSTOMER_CALL_ME,
}

export interface IModalInfo extends IModalCommon {
  type: EModalType.MODAL_INFO,
  props: {
    title: string,
    text?: string,
    btnText?: string,
    onOk?: () => void,
  },
}

export interface IModalMarket extends IModalCommon {
  type: EModalType.MODAL_MARKET,
  props: {
    title?: string,
  },
}

export interface IModalConfirm extends IModalCommon {
  type: EModalType.MODAL_CONFIRM,
  props: {
    title: string,
    text?: string,
    textCmp?: React.ReactNode,
    btnOkText: string,
    btnCancelText: string,
    onOk: () => void,
  },
}

export interface IModalOutOfStock extends IModalCommon {
  type: EModalType.MODAL_OUT_OF_STOCK,
  props: {
    title?: string,
  },
}

export interface IModalAuth extends IModalCommon {
  type: EModalType.MODAL_AUTH,
  props: {
    title?: string,
  },
}

export interface IModalPaymentInfo extends IModalCommon {
  type: EModalType.MODAL_PAYMENT_INFO,
  props: {
    title?: string,
  },
}

export interface IModalProducts extends IModalCommon {
  type: EModalType.MODAL_PRODUCTS,
  props: {
    order: IOrder,
  },
}

export interface IModalMessengers extends IModalCommon {
  type: EModalType.MODAL_MESSENGERS,
  props: {
    title?: string,
  },
}

export interface IModalPay extends IModalCommon {
  type: EModalType.MODAL_PAY,
  props: {
    order: IOrder,
    success: boolean,
    card: ICard,
  },
}

export interface IModalAddress extends IModalCommon {
  type: EModalType.MODAL_ADDRESS,
  props: {
    address?: string,
  },
}

export interface IModalCallMe extends IModalCommon {
  type: EModalType.MODAL_CUSTOMER_CALL_ME,
}

export interface IModalPayment extends IModalCommon {
  type: EModalType.MODAL_PAYMENT,
  props: {
    paymentType: EPaymentType,
    currentCardId?: string,
  },
}

export interface IModalDeliveryDate extends IModalCommon {
  type: EModalType.MODAL_DELIVERY_DATE,
  props: {
    deliverySlotId?: string,
    orderId?: string,
    address?: string,
    editable?: boolean,
    fromCart?: boolean,
    createdAt?: Date,
    deliveryDate?: string,
    deliveryTime?: string,
    orderItems?: { productId: string, quantity: number }[],
  },
}

export interface IModalReviewOrder extends IModalCommon {
  type: EModalType.MODAL_REVIEW_ORDER,
  props: {
    orderId: string,
  },
}

export type IModal =
  | IModalProduct
  | IModalDeliveryChange
  | IModalOrder
  | IModalCustomerCallMe
  | IModalInfo
  | IModalMarket
  | IModalConfirm
  | IModalOutOfStock
  | IModalAuth
  | IModalPaymentInfo
  | IModalProducts
  | IModalMessengers
  | IModalPay
  | IModalAddress
  | IModalPayment
  | IModalDeliveryDate
  | IModalReviewOrder
  | IModalCallMe

export type MutableStateModals = {
  modals: IModal[],
}

export type StateModals = Readonly<MutableStateModals>

const defStateModals: StateModals = {
  modals: [],
}

export const modals: Reducer<StateModals, Actions.Action> = (s = defStateModals, a): StateModals => {
  switch (a.type) {
    case Actions.MODAL_PUSH: {
      const newModals = [...s.modals]
      newModals.push(a.data)

      return {
        ...s,
        modals: newModals,
      }
    }
    case Actions.MODAL_POP: {
      const newModals = [...s.modals]
      newModals.pop()

      return {
        ...s,
        modals: newModals,
      }
    }
    case Actions.MODAL_CLEAR:
      return {
        ...s,
        modals: [],
      }
  }
  return s
}
